@keyframes showSubMenuLeft {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showSubMenuArrowLeft {
  from {
    transform: translateX(-30px) scaleX(2);
    opacity: 0;
  }
  to {
    transform: translateX(0) scaleX(2);
    opacity: 1;
  }
}

@keyframes showSubMenuRight {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes showSubMenuArrowRight {
  from {
    transform: translateX(30px) scaleX(2);
    opacity: 0;
  }
  to {
    transform: translateX(0) scaleX(2);
    opacity: 1;
  }
}

.region-header-top {
  @include x-rem('padding', 10px 0 0);
  border-bottom: 3px solid #f1f1f1;

  // WEBSITE V3 HEADER
  #block-edensprings-website-ng-blocks-website-ng-v3-header {
    #header-content {

      & > .container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        max-width: 1200px;
        margin: auto;
        align-items: center;

        ul {
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            padding: 0;
          }
        }

        .logo {
          order: 1;
          flex: 1 1 auto;

          a {
            display: inline-block;
            vertical-align: top;

            &.mini {
              display: none;
            }

            img {
              display: block;
            }
          }
        }

        .phone {
          order: 2;
          display: flex;
          align-items: center;
          color: $blue;

          &.domain-fr {

            i {
              margin-right: 15px;
              display: none;
            }

            .phone-text{
              display: inline-block;
              border: 1px solid #dddddd; //grey;
              vertical-align: top;
              font-family: "Arial Bold", "Arial", serif;
              background-color: #78b41e; //$purple_phone;
              position: relative;
              margin: 0 auto;

              span {
                &.digit {
                  background-color: $white;
                  padding: 2px 5px;
                  display: block;
                  @include x-rem("font-size", 16px);
                  text-align: center;
                  font-weight: bold;
                  line-height: 25px;
                  height: 25px;
                  //box-shadow: inset 0 2px 10px -4px rgba(0, 0, 0, 0.75);
                  a{
                    color: #78b41e; //$purple_phone;
                  }
                }
                &.phone-price {
                  vertical-align: middle;
                  @include x-rem("font-size", 12px);
                  display: block;
                  height: 11px;
                  line-height: 11px;
                  color: $white;
                  font-weight: bold;
                  padding-left: 15px;
                  text-transform: initial;
                  text-align: left;
                  margin: 3px 0 0;

                  &.border {
                    //box-shadow: inset 0 -7px 20px -8px rgba(0, 0, 0, 0.75);
                    margin: 0;
                    height: 14px;
                    line-height: 14px;
                    padding-bottom: 3px;
                  }
                }
                &.triangle {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 5px 5px 0 5px;
                  border-color: $white transparent transparent transparent;
                  position: absolute;
                  top: 25px;
                  left: 2px;
                }
              }
            }
          }

          a {
            color: $blue;
          }

          i {
            margin-right: 15px;
          }

          span {
            display: block;
            text-align: center;
          }

          .phone-number {
            a {
              line-height: 20px;
              @include x-rem('font-size', 22px);
              font-weight: bold;
            }
          }

          .open-hours {
            @include x-rem('font-size', 12px);
            line-height: 12px;
          }
        }

        .country-select {
          order: 3;
        }

        .language-select {
          margin-left: 30px;
          order: 4;
        }

        .header-select {
          position: relative;
          text-align: center;

          span {
            user-select: none;
            position: relative;
            display: block;
            z-index: 17;
            padding: 5px 32px 5px 16px;
            margin: 0 5px;
            border: 1px solid $blue;
            border-radius: 40px;
            color: $blue;
            font-weight: 500;
            letter-spacing: 2px;
            @include x-rem('font-size', 14px);
            background: url(../images/select-arrow.png) no-repeat right 12px center $white;
            cursor: pointer;
            text-transform: uppercase;
          }

          .item-list-container {
            display: none;
            position: absolute;
            z-index: 16;
            top: 0;
            margin: 0 5px;
            width: calc(100% - 10px);
            background: $white;
            @include box-shadow-content();
            margin-top: 15px !important; //hack for animation in JS
            padding: 20px 0 5px;

            ul {
              display: block;

              li {
                a {
                  text-transform: uppercase;
                  font-weight: 500;
                  display: block;
                  padding: 5px;
                  color: $blue;
                  @include x-rem('font-size', 13px);
                  letter-spacing: 1.8px;

                  &:hover {
                    color: $blue_light;
                  }
                }
              }
            }
          }

          &.language-select {
            .item-list {
              padding: 7px 0 0;
              border-radius: 0;
            }
          }

          &.bar {
            text-align: left;

            .item-list-container {
              padding: 30px 8px 20px;

              .item-list {
                height: 250px;
                overflow-y: scroll;
              }

              ul {
                li {
                  a {
                    font-weight: normal;
                    text-transform: none;
                  }
                }
              }
            }

            .simplebar-track.horizontal {
              display: none;
            }

            .simplebar-track.vertical {
              background: $white;
              width: 5px;
              border: 0.5px solid #fdfdfd;
              border-radius: 30px;

              .simplebar-scrollbar {
                right: 0;
                width: 5px;

                &:before {
                  background: $blue_light;
                  top: 0;
                  bottom: 0;
                  border-radius: 50px;
                }
              }
            }
          }
        }

        .user {
          order: 4;
          flex: 0 0 auto;

          &.padding {
            padding-left: 40px;
          }

          img {
            display: block;
          }
        }

        // MAIN MENU.
        .menu {
          order: 5;
          margin-top: 10px;
          //padding: 20px 0;
          width: 100%;
          border-top: 1px solid #f1f1f1;

          // LEVEL 1 MAIN MENU
          & > ul {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > li {
              position: relative;
              display: inline-block;
              @include x-rem('font-size', 16px);
              font-weight: 900;
              letter-spacing: 3px;
              padding: 20px 0;
              border-radius: 20px;
              & > span,
              & > a {
                display: block;
                user-select: none;
                cursor: pointer;
                color: $blue;

                &.active,
                &:hover {
                  color: $blue_light;
                  //color: #2968FE;
                }

                span {
                  vertical-align: middle;
                }
              }
              & > span {
                line-height: normal;
              }
              &.menu-item-contact {
                div.submenu {
                  ul {
                    li {
                      padding: 0px;
                      &:nth-child(odd) {
                        border-bottom: 0;
                        padding-top: 15px;
                        color: $blue;
                      }
                      &:nth-child(even) {
                        padding-bottom: 15px;
                        border-color: $blue;
                        padding-top: 5px;
                        padding-bottom: 15px;
                      }
                      span.contact-title {
                        text-transform: uppercase;
                      }
                      a.contact-link {
                        background: url(../images/phone_in_talk.svg) no-repeat left center;
                        background-size: 18px;
                        padding-left: 25px;
                        @include x-rem('font-size', 16px);
                      }
                    }
                  }
                }
              }

              div.submenu {
                display: none;
                animation: showSubMenuRight 500ms ease-in-out both;
                position: absolute;
                background: $white;
                top: 65px;
                z-index: 1500;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: nowrap;
                @include box-shadow-content();
                width: 450px;

                // LEVEL 2 MAIN MENU
                ul {
                  padding: 10px 25px;
                  flex: 0 0 250px;

                  li {
                    padding: 15px 5px;
                    border-bottom: 2px solid #f1f1f1;
                    &:last-child {
                      border: 0;
                    }
                    a,
                    span.js-link {
                      display: block;
                      @include x-rem('font-size', 14px);
                      line-height: 18px;
                      color: $blue;
                      font-weight: 600;

                      &.active,
                      &:hover {
                        color: $blue_light;
                        //color: #2968FE;
                      }
                    }

                    &.main-cat {
                      border-color: $blue;
                      padding: 10px 5px;

                      a {
                        @include x-rem('font-size', 15px);
                      }
                    }
                  }
                }

                img {
                  display: block;
                  flex: 0 0 200px;
                  min-height: auto;
                  max-height: 100%;
                }
              }

              &.active {
                &:after {
                  animation: showSubMenuArrowRight 500ms ease-in-out both;
                  position: absolute;
                  top: 33px;
                  margin: auto;
                  left: 0;
                  z-index: 1501;
                  right: 0;
                  content: '▲';
                  @include x-rem('font-size', 30px);
                  -webkit-transform: scaleX(2);
                  transform: scaleX(2);
                  color: $white;
                  text-align: center;
                  text-shadow: 0px -3px 4px rgba(69,73,91,0.16);
                }


                & > span {
                  color: $blue_light;
                }

                div.submenu {
                  display: flex;
                }
              }

              &.left {
                div.submenu {
                  animation: showSubMenuLeft 500ms ease-in-out both;
                  right: 0;
                  flex-direction: row-reverse;
                }

                &.active {
                  &:after {
                    animation: showSubMenuArrowLeft 500ms ease-in-out both;
                  }
                }
              }
            }
          }
        }
      }

      // Sticky MENU.
      &.sticky {
        position: fixed;
        z-index: 1501;
        top: 0;
        width: 100%;
        justify-content: space-between;
        flex-grow: 1;
        background: $white;
        padding: 10px 0;
        border-bottom: 3px solid #f1f1f1;

        .logo {
          flex: 1 0 auto;

          a.desktop {
            display: none;
          }
          a.mini {
            display: inline-block;
          }
        }

        .menu {
          display: none;
        }

        .user {
          order: 3;
          margin: 0;

          img {
            height: 32px;
          }
        }

        .country-select,
        .language-select {
          display: none;
        }
      }
    }
  }

  // TEMPLATE LANDING HEADER.
  #block-edensprings-website-ng-blocks-website-ng-header {
    margin-bottom: 10px;

    .header-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      img {
        display: block;
      }

      .header-right {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          margin-left: 20px;
        }
      }
    }
  }

  .user {
    color: $blue_light;
    //color: #2968FE;
    vertical-align: bottom;
    text-align: center;

    a {
      color: $blue_light;
      //color: #2968FE;
      @include x-rem('font-size', 14px);
      display: block;
      letter-spacing: 1.5px;

      span {
        display: block;
      }
    }
  }

  .user.only-mobile a {
    color: $blue;
  }

  .new_user {
    a {
      text-align: center;
      display: inline-block;
      background: $orange_cta;
      @include x-rem('padding', 8px 25px);
      color: $white;
      font-weight: 700;
      border-radius: 20px;

      &:hover {
        background: $orange;
      }
    }
  }
}

.infobar {
  overflow: hidden;
  text-align: center;

  p {
    @include x-rem('font-size', 20px);
    line-height: 20px;
    color: #fff;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

body.sticky {
  .page {
    padding-top: 150px;
  }
}

// ISRAEL
html[dir="rtl"] {
  .region-header-top {
    #block-edensprings-website-ng-blocks-website-ng-v3-header {
      #header-content {

        & > .container {
          .header-select {
            span {
              padding: 5px 16px 5px 32px;
              background-position: left 12px center;
            }
          }
        }
      }
    }
  }
}

