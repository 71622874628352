body.node-type-wngv-homepage {

  .content-title {
    text-align: center;

    h1 {
      padding: 0;
      @include x-rem('font-size', 40px);
      line-height: 42px;
      letter-spacing: 2px;
    }
  }

  ///--- TEXT BLOCK ---///
  article.node-text-block {
    margin: 0 auto;
    padding: 30px 0;

    div.node-text-block {
      max-width: 1200px;
      margin: 0 auto;

      .field-name-body {
        p {
          padding: 0;
        }
      }

      h1 {
        padding: 0;
      }
    }
  }

  .textblock-top {
    margin-bottom: 60px;
  }

  ///-- END TEXT BLOCK ---//

  .square-blocks {
    padding: 50px 0 80px;
    h2 {
      text-align: center;
    }

    .field-name-field-wngv-home-square-blocks {
      & > .field-items {
        display: flex;
        justify-content: space-around;
        align-items: center;

        & > .field-item {
          //flex: 0 0 50%;

          .square-block {
            max-width: 560px;
            margin: 0 20px;
            position: relative;
            text-align: center;
            @include box-shadow-content();

            img {
              display: block;
            }

            .inner {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 25px;
              height: 100%;
              align-items: center;
              margin: auto;

              h2 {
                margin-top: 0;
              }

              h2 a {
                color: $blue;
                @include x-rem('font-size', 34px);
                line-height: 36px;
                letter-spacing: 2px;
                margin: 0 0 15px;
                font-weight: 900;
                max-width: 100%; // IE11
              }

              .field-name-field-fc-square-desc {
                max-width: 75%;
                margin-bottom: 50px;
                @include x-rem('font-size', 15px);
                line-height: 20px;
              }

              a.btn {
                @include x-rem('font-size', 13px);
                font-weight: 900;
                line-height: 19px;
                padding: 15px 60px;
                letter-spacing: 1.5px;
              }
            }
          }
        }
      }
    }
  }

  // Flexbox component
  .flexbox-component {
    padding: 30px 40px;

    h2 {
      text-align: center;
    }

    .field-name-field-flexbox-items {
      & > .field-items {
        display: flex;
        justify-content: space-around;

        & > .field-item {
          flex: 0 0 22%;
        }
      }
    }

    .flexbox-item {
      text-align: center;
      font-size: 16px;      
      color: #252D5C;
      
      svg {
        height: 120px;
        width: 120px;
        fill: $blue_light;
        //fill: #2968FE !important;
      }

      .item-title {
        font-weight: 800;
        font-size: 19px;
      }
    }
  }
  
  .packages {
    h2 {
      text-align: center;
    }
    .field-name-field-three-pack-ref {
      margin: 80px 0 50px 0;
    }
    .field-name-field-three-pack-ref {
      & > .field-items {
        display: flex;
        justify-content: center;

        & > .field-item {
          margin: 0 10px;
          flex: 0 0 33%;
        }
      }
    }
    article.node-wngh-package {
      @include box-shadow-content();
      @include x-rem('padding', 20px 15px 60px);
      text-align: center;
      position: relative;
      margin: 0 10px;

      .field-name-field-max-three-textfields {
        font-size: 22px;
        text-align: left;

        .field-item {
          margin-bottom: 10px;
        }
      }

      .top-price {
        margin-bottom: 15px;

        .field-name-field-unique-text-limit-fifty {
          @include x-rem('font-size', 28px);
          color: $orange;
          //color: #2968FE;
          font-weight: bold;
          letter-spacing: 2px;
          line-height: 28px;
        }

        .field-name-field-unique-textfield {
          @include x-rem('font-size', 20px);
          line-height: 24px;
          color: $blue;
        }
      }

      .image {
        margin-bottom: 10px;

        img {
          display: inline-block;
        }
      }

      .infos {
        h3 {
          color: $blue;
          font-size: 24px;
          margin: 0 0 15px;
        }
      }

      .package-button a {
        position: absolute;
        bottom: -22px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background: $orange;
        background: $orange;
        display: inline-block;
        padding: 10px 35px;
        color: white;
        font-weight: 600;
        border-radius: 25px;
        width: 60%;
      }
    }
  }

  .textvsimage-block {
    padding: 80px 0;
    background: $white_medium;

    .textvsimage {
      box-shadow: none;
      padding: 0;
      justify-content: center;
      background: none;

      .field-name-field-wngh-tvi-image {
        flex: 0 0 52%;
      }

      .content {
        flex: 0 0 45%;
        margin-left: -5%;
        background: $blue;
        color: $white;
        padding: 50px 30px;
        overflow: hidden;
        z-index: 3;

        h2 {
          color: $white;
        }

        .field-name-field-wngh-tvi-description {
          p {
            color: $white;
          }
        }

        a.btn-vs {
          border: 1px solid $white; //#000E2E;
          color: $white;
          padding: 12px 15px;

          svg {
            fill: $white;
          }

          &:hover {
            color: $blue;
            background: $white;

            svg {
              fill: $blue;
            }
          }
        }
      }
    }
  }

  .about {
    padding: 60px 0 30px;
    text-align: center;
    background: $white_light;

    h3 {
      font-weight: 900;
      @include x-rem('font-size', 34px);
      letter-spacing: 2px;
      line-height: 36px;
      margin: 0 0 10px;
      color: $blue;
    }

    .description {
      margin-bottom: 30px;
      overflow: hidden;

      p {
        font-weight: 500;
        @include x-rem('font-size', 16px);
      }

      &:after {
        display: block;
        content: '';
        width: 2px;
        height: 60px;
        background-color: $blue_light;
        margin: 30px auto 0;

      }
    }

    .circle-cards {
      .field-name-field-wngh-home-about-us {
        & > .field-items {
          display: flex;
          justify-content: space-around;
          align-items: center;

          & > .field-item {
            a.card-link {
              .circle-card {
                position: relative;
                max-width: 250px;
                margin: auto;

                .field-name-field-wngh-cta-image {
                  img {
                    border-radius: 50%;
                  }
                }

                .content {
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  border-radius: 50%;
                  width: 250px;
                  height: 250px;
                  padding: 40px 10px;
                  background: rgba(37, 45, 92, 0.8);
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  transition: opacity 1s ease;

                  h3 {
                    margin: 0 0 5px;
                    color: $white;
                    @include x-rem('font-size', 20px);
                    line-height: 22px;
                  }

                  .field-name-field-wngh-cta-desc {
                    color: $white;
                  }

                  .btn-go {
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 50px;
                    height: 50px;
                    background: $blue_light;
                    //background: #2968FE;
                    border-radius: 50%;

                    i {
                      line-height: 50px;
                      color: $white;
                      @include x-rem('font-size', 30px);
                    }
                  }
                }
              }

              &:hover,
              &:active {
                .content {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  &.touch {
    .circle-card {
      .content {
        opacity: 1!important;
      }
    }
  }
}
