.page-help {

  .page {
    padding-bottom: 0;
  }

  .content-help {
    text-align: center;
    width: 70%;
    margin: auto;

    h2 {
      @include x-rem('font-size', 34px);
      line-height: 38px;
      padding-bottom: 60px;
      position: relative;
      margin: 0;

      &:after {
        content: '';
        width: 2px;
        height: 50px;
        bottom: -10px;
        background: $blue_light;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .top-blocks,
    .bottom-blocks {
      display: flex;
      justify-content: center;
      margin: 30px 0 80px;

      & > div {
        flex: 0 0 calc(50% - 15px);
        @include box-shadow-content();
        padding: 30px 20px 50px;
        position: relative;
        margin: 0 15px;

        .help-block {
          a {
            color: $blue;
          }

          span.plus,
          a.btn-custom {
            cursor: pointer;
            position: absolute;
            bottom: -20px;
            left: 0;
            right: 0;
            margin: auto;
            width: 50px;
            height: 50px;
            background: #06A7E0;
            //background: #2968FE;
            border-radius: 50%;

            i {
              line-height: 50px;
              color: #fff;
              @include x-rem('font-size', 30px);
            }

            &.active {
              cursor: initial;
            }
          }

          svg {
            height: 60px;
            width: auto;
            position: relative;
            margin: 20px 0 10px;
          }

          span.name {
            @include x-rem('font-size', 20px);
            color: $blue;
            font-weight: bold;
            line-height: 24px;
            letter-spacing: 2px;
            display: block;
            margin-bottom: 20px;
          }

          &.subscribe-online {
            a.btn-custom {
              background: $orange_cta;
            }
          }

          &.manage-account,
          &.customer-service {
            span.plus {
              background: $blue;
            }
          }
        }
      }
    }
  }

  .bottom-open {
    background: $white_medium;

    #customer {
      padding-top: 60px;
      padding-bottom: 60px;
      display: none;

      .call-center-phone {
        color: $blue;
        vertical-align: middle;
        font-weight: bold;
        text-align: center;
        margin-top: 30px;

        i {
          margin-right: 15px;
        }

        div.phone-number {
          display: inline-block;
          vertical-align: middle;

          a {
            color: $blue;
            font-weight: bold;
            @include x-rem('font-size', 24px);
            line-height: 24px;
            letter-spacing: 2px;

            &:hover {
              color: $blue_light;
            }
          }
        }
      }

      form {
        width: 40%;
        margin: auto;

        .form-item {
          padding: 3px 0;
        }

        // Files (CV)
        .webform-component-file {
          margin: 3px 0;
          padding: 15px;
          text-align: center;
          background: $white;

          .form-managed-file {
            margin: 15px 0;

            span.file {
              margin-right: 10px;
              display: inline-block;
            }

            input[type="submit"] {
              background: $red;
              padding: 6px 25px;
            }
          }
        }

        .webform-component--rgpd {
          height: 78px;
          overflow: hidden;
          padding-bottom: 5px;
          margin: 10px 0;

          a {
            color: $blue;
            font-weight: 500;
          }
        }

        .webform-component-checkboxes {
          padding-top: 5px;
          border-top: 1px solid rgba(0, 0, 0, 0.2);

          .form-item {
            padding: 0;
            margin: 15px 0 0;
          }

          input[type="checkbox"] {
            position: absolute;
            left: -9999px;

            & + label {
              cursor: pointer;

              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 16px;
                height: 16px;
                border: 2px solid $blue;
              }
            }

            &:checked {
              & + label {
                &:before {
                  border: 2px solid $blue;
                }

                &:after {
                  content: '';
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  display: block;
                  width: 8px;
                  height: 8px;
                  background: $blue;
                }
              }
            }
          }

          label {
            padding-left: 30px;
            display: inline-block;
            @include x-rem('font-size', 12px);
            line-height: 14px;
            font-weight: 500;
            color: $grey;
            vertical-align: top;
          }
        }

        .form-actions {
          text-align: center;
        }

        .simplebar-track.horizontal {
          display: none;
        }

        .simplebar-track.vertical {
          background: rgba(0, 0, 0, 0.2);

          .simplebar-scrollbar {
            &:before {
              background: rgba(0, 0, 0, 0.7);
            }
          }
        }

        .simplebar-content {
          .simplebar-container {

            p {
              color: $grey;
            }
          }
        }
      }

      .links {
        text-align: center;

        a {
          color: $blue_light;
          font-weight: 500;
          @include x-rem('font-size', 14px);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    #manage {
      padding-top: 60px;
      padding-bottom: 60px;
      display: none;
      text-align: center;

      .manage-content {
        width: 33%;
        margin: auto;

        #user-login-text-block {
          display: none;
        }

        #login-form {
          padding: 0;
          background: transparent;
          box-shadow: none;
          form {
            width: 100%;
          }
        }
      }
    }
  }

  .others-help-below-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
    -webkit-box-shadow: 0 2px 5px 0 rgba(69,73,91,0.16);
    box-shadow: 0 2px 5px 0 rgba(69,73,91,0.16);
    padding: 30px 20px 50px;
    position: relative;
    margin: 30px auto;
    width: 70%;
  }
}
