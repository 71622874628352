// ### RESET
@import "base/_normalize";

// ### HELPERS
@import "helpers/_variables";
@import "helpers/_mixins";

// ### BASE
@import "base/_global";
@import "base/_layout";
@import "base/_typo";
@import "base/_admin";
@import "base/_responsive";

// ### COMPONENTS WEBSITE
@import "components/uk/_global";
@import "components/uk/_header";
@import "components/uk/_footer";
@import "components/uk/_arrows";
@import "components/uk/_form";
@import "components/uk/_breadcrumb";
@import "components/uk/_user";
@import "components/uk/_commonform";
@import "components/uk/_banner";
@import "components/uk/_text-vs-image";
@import "components/uk/_homepage";
@import "components/uk/_offers";
@import "components/uk/_type-product";
@import "components/uk/_product";
@import "components/uk/_business_line";
@import "components/uk/_partners";
@import "components/uk/_eden_in";
@import "components/uk/_career";
@import "components/uk/_help";
@import "components/uk/_well-being";
@import "components/uk/_blog";
@import "components/uk/_faq";
@import "components/uk/_repertory";
@import "components/uk/_popin";
@import "components/uk/_cta";
@import "components/uk/_template-landing";
@import "components/uk/_messages";
@import "components/uk/_admin";
@import "components/uk/_template-landing-form-column";
@import "components/uk/_business_line_cro";
@import "components/uk/_banner_cro.scss";
@import "components/uk/_catalog.scss";
@import "components/uk/_client.scss";

// ### COMPONENTS WEBSITE RESPONSIVE
@import "components/uk/responsive/_header";
@import "components/uk/responsive/_footer";
@import "components/uk/responsive/_webform";
@import "components/uk/responsive/_breadcrumb";
@import "components/uk/responsive/_user";
@import "components/uk/responsive/_commonform";
@import "components/uk/responsive/_banner";
@import "components/uk/responsive/_text-vs-image";
@import "components/uk/responsive/_homepage";
@import "components/uk/responsive/_offers";
@import "components/uk/responsive/_product";
@import "components/uk/responsive/_partners";
@import "components/uk/responsive/_eden_in";
@import "components/uk/responsive/_career";
@import "components/uk/responsive/_help";
@import "components/uk/responsive/_well-being";
@import "components/uk/responsive/_blog";
@import "components/uk/responsive/_faq";
@import "components/uk/responsive/_repertory";
@import "components/uk/responsive/_popin";
@import "components/uk/responsive/_cta";
@import "components/uk/responsive/_template-landing";
@import "components/uk/responsive/_admin";
@import "components/uk/responsive/_template-landing-form-column";
@import "components/uk/responsive/_business_line_cro";
@import "components/uk/responsive/_banner_cro.scss";
@import "components/uk/responsive/_catalog.scss";

// ### DIGITAL ONBOARDING COMPONENTS
@import "components/digital_onboarding/_header";
@import "components/digital_onboarding/_footer";
@import "components/digital_onboarding/_digital-onboarding";

// ### DIGITAL ONBOARDING COMPONENTS RESPONSIVE
@import "components/digital_onboarding/responsive/_digital-onboarding";

// ### RESIDENTIAL ONBOARDING COMPONENTS
@import "components/rob/_header";
@import "components/rob/_rob";

// ### RESIDENTIAL ONBOARDING COMPONENTS RESPONSIVE
@import "components/rob/responsive/_rob";

.simple-login{

  #quick-links{
    display: none;
  }
}