// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  .region-header-top {
    #block-edensprings-website-ng-blocks-website-ng-v3-header {
      #header-content {
        // MAIN MENU.
        .menu {
          // LEVEL 1 MAIN MENU
          & > ul {
            max-width: 100%;
          }
        }
      }
    }

    #block-edensprings-website-ng-blocks-website-ng-header {
      .header-container {
        .search {
          span {
            display: none;
          }
        }
      }
    }
  }


  .infobar {
    p {
      @include x-rem('font-size', 16px);
      line-height: 16px;
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  .region-header-top {
    border-bottom: 1px solid #f1f1f1;
    @include x-rem('padding', 10px 0);
    
    #block-edensprings-website-ng-blocks-website-ng-v3-header {
      margin-bottom: 0;
      padding-right: 10px;

      #header-content {
        & > .container {
          justify-content: space-between;

          .menu-mobile {
            display: block;
            flex: none;
            @include x-rem('padding', 10px);

            &.is-active {
              .burger-icon {
                background-color: transparent;

                &::before {
                  transform: translateY(8px) rotate(45deg);
                }

                &::after {
                  transform: translateY(-8px) rotate(-45deg);
                }
              }
            }
          }

          .burger-icon {
            position: relative;
            margin-top: 8px;
            margin-bottom: 8px;
          }

          .burger-icon,
          .burger-icon::before,
          .burger-icon::after {
            display: block;
            @include x-rem('width', 25px);
            @include x-rem('height', 3px);
            background-color: $blue;
            outline: transparent solid 1px;
            transition: background-color .3s ease, transform .3s ease;
          }

          .burger-icon::before,
          .burger-icon::after {
            position: absolute;
            content: "";
          }

          .burger-icon::before {
            top: -8px;
          }

          .burger-icon::after {
            top: 8px;
          }

          .logo {
            text-align: center;

            a.mini {
              display: inline-block;
            }
          }

          .phone {
            i {
              @include x-rem('font-size', 24px);
            }

            &.domain-fr{

              i {
                display: block;
              }

              .phone-text {
                display: none;
              }
            }

            .phone-text {
              display: none;
            }
          }

          .country-select {
            display: none;
            order: 5;
            position: absolute;
            z-index: 99994;
            top: 60px;
            right: 101px;
            padding: 10px 0;

            .default {
              float: right;
            }

            .item-list-container {
              margin-top: 25px !important;
            }

            &.right {
              right: 20px;
            }
          }

          .language-select {
            display: none;
            order: 6;
            position: absolute;
            z-index: 99994;
            top: 60px;
            right: 0;
            padding: 10px 0;
            margin-right: 20px;

            .default {
              float: right;
            }

            .item-list-container {
              margin-top: 25px !important;
            }
          }

          .menu {
            display: none;
            height: calc(100% - 60px); //$menu_mobile_height
            position: absolute;
            width: 100%;
            z-index: 99993; // higher than lightboxes
            border: none;
            background: $blue;
            overflow-y: auto;
            top: 60px;
            left: 0;
            margin: 0;
            padding: 53px 0 0;

            .top-menu {
              display: flex;
              justify-content: flex-end;
              padding: 10px 0;
              margin: 0 20px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);

              .header-select {
                .item-list-container {
                  display: none;
                }
              }
            }

            & > ul {
              display: block;

              & > li {
                margin: 0 20px;
                display: block;
                padding: 10px 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                border-radius: 0;
                padding-left: 10px;

                & > span,
                a {
                  color: $white;
                  display: block;

                  &.active,
                  &:hover {
                    color: $white,
                  }
                }

                i {
                  float: right;
                }

                div.submenu {
                  display: none;
                  position: static;
                  box-shadow: none;
                  width: auto;

                  ul {
                    min-width: auto;
                    width: auto;
                  }
                }

                &:after {
                  display: none;
                }

                &.active {
                  background: $white;
                  padding: 10px 20px;
                  margin: 0;

                  & > span,
                  & > a {
                    color: $blue_light;
                    //color: #2968FE;
                  }

                  i {
                    transform: rotate(180deg);
                  }

                  div.submenu {
                    border-top: 1px solid #f1f1f1;
                    margin-top: 10px;

                    ul {
                      margin: 10px 0 10px 10px;
                      padding: 0;
                    }

                    li {
                      padding: 8px 5px;
                    }
                  }
                }

                &:first-child {
                  border-top: 1px solid rgba(255,255,255,0.2);
                }
              }
            }
          }
        }

        & > .container {

          &.has-phone {
            justify-content: unset;

            .logo {
              flex: 1 0 0;
              padding-left: 43px;
            }
          }

          .user {
            &.padding {
              padding: 0;
            }

            img {
              height: 30px;
            }
          }
        }
      }
    }


    #block-edensprings-website-ng-blocks-website-ng-header {
      margin: 0;

      .header-container {
        padding: 0 10px;

        .logo {
          img {
            display: block;
          }
        }

        .header-right {
          & > div {
            &.new_user {
              a {
                padding: 3px 12px;
                @include x-rem('font-size', 14px);
              }
            }
          }
        }
      }
    }
  }
}
